import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Person } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme'
import { sprintf } from 'sprintf-js';
import gql from 'graphql-tag';
import { Button, Content, useDimensions, ScrollView, ContentNode, HTML, InputCheckbox, PersonImage, useActivePerson, useAuth, useLang, WaitMessage, WARM_GREY  } from '@shapeable/ui';

// -------- Props -------->

export type TermsAgreeDialogProps = Classable & HasChildren & {
  margin?: number;
}

export const TermsAgreeDialogDefaultProps: TermsAgreeDialogProps = {
  margin: 20,
};

// -------- Types -------->

type TermsProps = {
  availableHeight: number;
};

type DialogProps = {
  margin: number;
};

// -------- Styles -------->

const OverlayStyles = breakpoints({
  base: css`
    background: rgba(0, 0, 0, 0.6);
    height: 100%;
    width: 100%;
    position: fixed;
    overflow: hidden;
    z-index: 1000;
  `,
});


const DialogStyles = breakpoints({
  base: css`
    font-family: 'IBM Plex Sans', sans-serif;
    position: absolute;
    display: flex;
    box-shadow: 0px 2px 5px -3px rgba(0, 0, 0, 0.6); 
    font-size: ${theme.FONT_SIZE(15)};
    flex-direction: column;

    ${({ margin }: DialogProps ) => css`
      top: ${margin}px;
      left: ${margin}px;
      width: calc(100% - ${2 * margin}px);
      height: calc(100% - ${2 * margin}px);
    `}
  `,
});

const BodyStyles = breakpoints({
  base: css`
    text-align: left;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    background: ${theme.COLOR('light')};
  `,
});


const HeaderStyles = breakpoints({
  base: css`
    display: flex;
    background: ${WARM_GREY};
    align-items: center;
    background: ${theme.BG('form-header')};
    padding: ${theme.UNIT(2)} ${theme.UNIT(5)};
  `,
  tablet: css`
    padding: ${theme.UNIT(4)} ${theme.UNIT(5)};
  `,
});

const PhotoStyles = breakpoints({
  base: css`
    margin-right: ${theme.UNIT(5)};
  `,
});

const IntroContentStyles = breakpoints({
  base: css`
    margin: 0;
    line-height: 1.5em;
    padding: ${theme.UNIT(1)};
    color: ${theme.COLOR('text')};
    font-family: 'IBM Plex Sans', sans-serif;
  `,
  tablet: css`
    padding: ${theme.UNIT(2)};
  `
});

const ControlsStyles = breakpoints({
  base: css`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 1;
    background: ${WARM_GREY};
    background: ${theme.BG('form-header')};
    border-top: 1px solid ${theme.COLOR('line-mid')};
    padding: ${theme.UNIT(6)} ${theme.UNIT(5)} ${theme.UNIT(6.2)};
  `,
});


const TermsStyles = breakpoints({
  base: css`
    flex-grow: 1;
    ${({ availableHeight  }: TermsProps ) => css`
      height: ${availableHeight}px;
    `}
    box-sizing: border-box;
    border-top: 1px solid ${theme.COLOR('line-mid')};
  `,
});

const ContentStyles = breakpoints({
  base: css`
    padding: ${theme.UNIT(5)} ${theme.UNIT(5)} ${theme.UNIT(20)};
  `,
});



const WaitMessageStyles = breakpoints({
  base: css`
    height: 700px;
    max-height: 44vh;
    display: flex;
    color: ${theme.COLOR('light')};

    .shp--loader__message {
      color: ${theme.COLOR('light')};
    }
    
    font-size: ${theme.FONT_SIZE(18)};
    align-items: center;
    justify-content: center;
    border-top: 1px solid ${theme.COLOR('line-mid')};
  `,
});

const AgreeButtonStyles = breakpoints({
  base: css`
    background: #FFF;
  `,
});



// -------- Components -------->

const My = {

  Container: styled.div<any>`${OverlayStyles}`,

    Dialog: styled.div<DialogProps>`${DialogStyles}`,

    WaitMessage: styled(WaitMessage)`${WaitMessageStyles}`,

    Body: styled.div`${BodyStyles}`,
      Header: styled.header`${HeaderStyles}`,
        Photo: styled(PersonImage)`${PhotoStyles}`,
        IntroContent: styled.p`${IntroContentStyles}`,
      Terms: styled(ScrollView)<TermsProps>`${TermsStyles}`,

      Content: styled(ContentNode)`${ContentStyles}`,

    Controls: styled.div`${ControlsStyles}`,
      AgreeButton: styled(Button)`${AgreeButtonStyles}`,
      
};


export const TermsAgreeDialog: React.FC<TermsAgreeDialogProps> = (props) => {
  const { className, margin } = props;
  const { user } = useAuth();
  const { person, isLoading, setHasAgreedToTerms, hasAgreedToTerms } = useActivePerson();
  const t = useLang();

  const [ dialogRef, dialogDimensions ] = useDimensions();
  const [ headerRef, headerDimensions ] = useDimensions();
  const [ controlsRef, controlsDimensions ] = useDimensions();

  const headerTemplate = t(
  `
    <b>Welcome %s</b>. Please review our Terms of Use to continue using the platform on this device.
  `);

  const headerText = sprintf(headerTemplate, user.givenName);
  const buttonLabel = t('I agree to the Terms of Use');

  const termsContent = {
    text: `
Enim proident sunt incididunt do aliqua irure cupidatat nulla ad. Nostrud minim eiusmod veniam in incididunt fugiat dolor anim. Ad amet nulla in culpa commodo pariatur nostrud non aliqua est voluptate. Culpa officia incididunt qui ullamco ullamco eiusmod velit aliquip. Aliquip excepteur consectetur aliquip ea fugiat sit id. Fugiat proident aute laboris ullamco officia elit.

Consectetur velit excepteur culpa dolor dolor qui fugiat ipsum dolore velit. Reprehenderit nostrud excepteur non excepteur voluptate occaecat. Duis pariatur culpa sint proident et velit. Ipsum et cupidatat anim ullamco. Proident mollit culpa eiusmod ipsum magna proident Lorem occaecat.

In Lorem in cupidatat consectetur incididunt velit nulla proident quis officia. Sit amet enim fugiat incididunt excepteur aliqua minim. Sint laborum eu pariatur et non eu do excepteur ullamco irure occaecat sit.

Anim ullamco deserunt mollit sint Lorem ut anim Lorem. Eiusmod anim occaecat nisi consequat cupidatat in incididunt irure non aliqua velit amet deserunt non. Commodo voluptate veniam cillum veniam officia sint cillum ad sint sint mollit excepteur amet. Consequat Lorem excepteur mollit exercitation cillum pariatur nisi ea proident duis et magna dolor.

Non aute culpa velit laborum elit exercitation labore. Labore proident ipsum reprehenderit cupidatat elit incididunt tempor irure elit. Minim nisi et est eu fugiat do deserunt voluptate pariatur laborum.

Excepteur ipsum aute in ad non velit culpa reprehenderit aute non. Id ipsum irure adipisicing in proident qui commodo veniam ex elit laborum. Incididunt consequat deserunt nostrud ipsum aliqua anim ut voluptate culpa sit. Adipisicing voluptate ullamco Lorem occaecat enim est mollit ipsum consequat laborum.

Exercitation ullamco non laborum mollit adipisicing et minim. Culpa excepteur ut veniam Lorem aliquip qui consequat sint. Id minim consectetur consequat quis ad laboris duis aliquip magna aliqua qui ex dolor. Labore anim exercitation sit reprehenderit consectetur nisi labore eiusmod cupidatat ex ad.

Sunt cupidatat commodo ullamco consequat in excepteur eu pariatur sit. Sit ipsum reprehenderit eu ea. Voluptate ad quis eu adipisicing eiusmod deserunt officia aliqua. Sunt ex aute magna elit ex consequat laborum officia veniam ex consectetur eu.

Sunt fugiat enim ea aute deserunt consequat mollit dolore Lorem aute reprehenderit. Consequat cillum ea nulla consectetur voluptate ex ex nulla Lorem consectetur dolore aliquip voluptate laboris. Officia esse laborum incididunt nulla culpa irure veniam velit nisi ut culpa ipsum. Eu veniam consequat magna irure labore aliqua sit adipisicing do deserunt veniam. Excepteur enim culpa deserunt do magna enim nulla cupidatat dolor excepteur. Aute incididunt ex enim nostrud dolore voluptate deserunt amet tempor non. Qui Lorem mollit ad ea aliquip.

Culpa adipisicing eiusmod cillum incididunt deserunt minim adipisicing adipisicing excepteur elit. Pariatur culpa nulla anim deserunt do aliqua nostrud duis duis exercitation qui reprehenderit. Eu non enim minim cillum culpa. Id elit pariatur elit eiusmod culpa quis do duis esse excepteur quis.
    `
  };

  return (
    <My.Container ref={dialogRef}>
    {
      isLoading ?
        <My.WaitMessage  /> :
        <My.Dialog
          margin={margin}
          title={t('Welcome')}
          className={className}
      >
      <My.Body>
      {
        <>
        <My.Header ref={headerRef}>
          {}
          <My.Photo size={64} entity={person} />
          <My.IntroContent><HTML text={headerText} /></My.IntroContent>
        </My.Header>
        {
          isLoading ?
          <My.WaitMessage /> :
          <My.Terms availableHeight={dialogDimensions.height - (headerDimensions.height + controlsDimensions.height + (2 * margin))}>
            <My.Content entity={termsContent} />
          </My.Terms>
        }
        </>
      }
      </My.Body>
      <My.Controls ref={controlsRef}>
        <My.AgreeButton onClick={() => { setHasAgreedToTerms(true); }}>{buttonLabel}</My.AgreeButton>
      </My.Controls>
      </My.Dialog>
    }
    </My.Container>
  )
};

TermsAgreeDialog.defaultProps = TermsAgreeDialogDefaultProps;
