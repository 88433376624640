import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react'; // tslint:disable-line
import { platformProviderValue } from '@shapeable/web';
import { LegalSystemsProvider as Provider } from '../../components/context/legal-systems-context';

const LEGAL_SYSTEMS_QUERY = graphql`
  query LegalSystemsQuery {
    platform {
      allLegalSystems {
        id name path slug __typename _schema { label pluralLabel }
      }
    }
  }
`

export const LegalSystemsProvider: React.FC<any> = ({ children }) => {
  return <Provider value={platformProviderValue(useStaticQuery(LEGAL_SYSTEMS_QUERY), 'allLegalSystems')}>{children}</Provider>;
}