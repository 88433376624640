module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-2MB4F8875R"],"gtagConfig":{"anonymize_ip":true,"cookie_expires":0},"pluginConfig":{"head":false,"respectDNT":false}},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"36456981a14f60bb0c3ef336b93dd62e"},
    },{
      plugin: require('../node_modules/@shapeable/gatsby-theme-shapeable-web/gatsby-browser.js'),
      options: {"plugins":[],"fonts":["IBM+Plex+Sans:300,300i,400,400i,500,500i,600,600i,700","IBM+Plex+Serif:300,300i,400,400i,500,600","IBM+Plex+Sans+Condensed:300,400,500,600","Roboto+Mono:ital,wght@0,300;0,400;0,500;0,600;1,300;1,400;1,500;1,600"],"entities":{"Post":{},"Economy":{},"FeedEntry":{},"Video":{}},"siteMetadata":{"title":"","description":"","image":"","video":"","twitter":"","threads":"","linkedin":"","facebook":"","flickr":"","tiktok":"","youtube":"","instagram":"","name":"","logo":"","titleTemplate":"%s - %s","openGraphTitleTemplate":"%s - %s"}},
    }]
