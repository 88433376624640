import React, { useState } from 'react';
import { Classable, Dictionary, HasChildren, Page, Shapeable } from '@shapeable/types';
import styled, { css } from 'styled-components';
const cls = classNames('contribute-form');
import { breakpoints, theme } from '@shapeable/theme';
import { ErrorList, Field, FormButton, FormLoader, Input, Select, SelectOption, TextArea, usePage, useContributeForm, useLinearNavigation,
LinearNavigationItem, useSiteRecaptchaKey, ContentNode, usePages, useActiveEntity, useLang, useSiteOwnerName, MarkdownContent, useTermsPagePath, MarkdownContentLinkTargetBlank, InputCheckbox } from '@shapeable/ui';
import { classNames, onSubmitNetlifyForm } from '@shapeable/utils';
import { sprintf } from 'sprintf-js';
import EmailValidator from 'email-validator';
import { filter, includes, trim, without } from 'lodash';
import ReCAPTCHA from 'react-google-recaptcha';
import { INTRO_FORM_TEXT } from '../../data';
import { ORANGE } from '../../theme';

// -------- Types -------->

export type ContributeFormProps = Classable & HasChildren & {
}

export const ContributeFormDefaultProps: ContributeFormProps = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    min-height: 200px;
  `,
});

const FieldStyles = breakpoints({
  base: css`
    
  `,
});

const SelectStyles = breakpoints({
  base: css`
    width: 100%;
    font-size: ${theme.FONT_SIZE(14)};
  `,
});

const FormStyles = breakpoints({
  base: css`
    padding-bottom: ${theme.UNIT(3)};
  `,
});

const IntroductionStyles = breakpoints({
  base: css`
    color: ${theme.COLOR('light')};
    margin-bottom: ${theme.UNIT(3)};
    text-align: center;
  `,
});



const TextAreaStyles = breakpoints({
  base: css`
    width: 100%;
    height: 150px;
    color: ${theme.COLOR('dark')};
  `,
});



const InputStyles = breakpoints({
  base: css`
    width: 100%;
    color: ${theme.COLOR('dark')};
  `,
});

const SubmitButtonStyles = breakpoints({
  base: css`
    width: 100%;
    margin-top: ${theme.UNIT(2)};
  `,
});


const OverlayStyles = breakpoints({
  base: css`
    z-index: 100;
    box-sizing: border-box;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  `,
});


const LoaderStyles = breakpoints({
  base: css`
    ${OverlayStyles};
  `,
});

const ThankyouStyles = breakpoints({
  base: css`
    ${OverlayStyles};
    background: rgba(0,0,0,0.9);
    padding: ${theme.UNIT(4)};
    width: 100%;
    flex-grow: 1;
    color: ${theme.COLOR('light')};
    font-weight: 400;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    text-align: center;
    p {
      margin: ${theme.UNIT(4)};
    }
  `,
});

const ThankyouMessageStyles = breakpoints({
  base: css`
    height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    font-size: 1.25em;
  `,
});


const DoneButtonStyles = breakpoints({
  base: css`
    ${SubmitButtonStyles};
    
  `,
});

const ErrorsStyles = breakpoints({
  base: css`
    color: ${theme.COLOR('red')};
    background: #EFEEEE;
    padding: ${theme.UNIT(4)};
    margin-bottom: ${theme.UNIT(4)};
    font-weight: 500;
    font-size: ${theme.FONT_SIZE(14)};
  `,
});

const InputCheckboxStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    gap: ${theme.UNIT(5)};
    color: ${theme.COLOR('text')};
    margin-bottom: ${theme.UNIT(6)};
    align-self: stretch;
    margin-bottom: ${theme.UNIT(6)};

    .shp--input-checkbox__checkbox {
      margin-left: 0;
    }
    .shp--icon {
      ${theme.FILL(ORANGE)}
    }
  `,
});

// -------- Components -------->

const INQUIRY = 'inquiryType';
const PAGE = 'page';
const TYPE = 'inquiryType';
const DETAILS = 'message';
const NAME = 'name';
const EMAIL = 'email';
const COMPANY = 'company';
const STAKEHOLDER_TYPE = 'stakeholderType';
const BE_CONTACTED = 'AgreeToBeContacted';
const AGREE = 'agreeToTerms';

const FORM_NAME = 'contribute';

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Loader: styled(FormLoader)`${LoaderStyles}`,
    Thankyou: styled.div`${ThankyouStyles}`,
      ThankyouMessage: styled.div`${ThankyouMessageStyles}`,
      DoneButton: styled(FormButton).attrs({ type: 'button' })`${DoneButtonStyles}`,

    Agree: styled(InputCheckbox).attrs({ id: AGREE, name: AGREE })`${InputCheckboxStyles}`,
    AgreeToContact: styled(InputCheckbox).attrs({ id: BE_CONTACTED })`${InputCheckboxStyles}`,

    Form: styled.form`${FormStyles}`,
      Introduction: styled(ContentNode)`${IntroductionStyles}`,
      InquiryTypeField: styled(Field).attrs({ id: INQUIRY, label: 'What would you like to Contact us about?:' })`${FieldStyles}`,
        InquiryType: styled(Select).attrs({ id: INQUIRY, placeholder: 'Select an Inquiry Type' })`${SelectStyles}`,
      PageField: styled(Field).attrs({ id: PAGE, label: 'Commenting On:' })`${FieldStyles}`,
        Page: styled(Select).attrs({ id: PAGE, placeholder: 'Select a Page' })`${SelectStyles}`,
      
      DetailsField: styled(Field).attrs({ id: DETAILS })`${FieldStyles}`,
        Details: styled(TextArea).attrs({ id: DETAILS, placeholder: 'Message' })`${TextAreaStyles}`,
      
      NameField: styled(Field).attrs({ id: NAME, label: 'Your Details:' })`${FieldStyles}`,
        Name: styled(Input).attrs({ id: NAME, placeholder: 'Name or Pseudonym' })`${InputStyles}`,
      
      EmailField: styled(Field).attrs({ id: EMAIL })`${FieldStyles}`,
        Email: styled(Input).attrs({ id: EMAIL, placeholder: 'Email' })`${InputStyles}`,
      
      CompanyField: styled(Field).attrs({ id: COMPANY })`${FieldStyles}`,
        Company: styled(Input).attrs({ id: COMPANY, placeholder: 'Company Affiliation' })`${InputStyles}`,

      StakeholderTypeField: styled(Field).attrs({ id: STAKEHOLDER_TYPE })`${FieldStyles}`,
        StakeholderType: styled(Select).attrs({ id: STAKEHOLDER_TYPE, placeholder: 'Stakeholder Type' })`${SelectStyles}`,

    Errors: styled(ErrorList)`${ErrorsStyles}`,

    SubmitButton: styled(FormButton)`${SubmitButtonStyles}`,
};

export const ContributeForm: Shapeable.FC<ContributeFormProps> = (props) => {
  const { className } = props;
  const form = useContributeForm();

  const { values, patchValues } = form;
  const [ token, setToken ] = useState('');
  const [ errors, setErrors ] = useState<string[]>([]);
  const [ errorFields, setErrorFields ] = useState<string[]>([]);
  
  const pages = usePages() || [];
  const currentPage = useActiveEntity();

  const SITE_OWNER_NAME = useSiteOwnerName();
  const TERMS_PAGE_PATH = '/terms-of-use';

  const t = useLang()

  const ref = form.useClickOutside();
  
  const pageOption = (item: Page) => {
    if (!item) {
      return null;
    }

    const label = filter([item.name]).join('. ');

    return {
      label,
      value: item.path,
    }
  };

  const pageOptions: SelectOption[] = pages.map(pageOption);
  let pageDefaultOption = pageOption(pages.find(item => item.path === currentPage?.__path));

  if (!pageDefaultOption) {
    // if the page is not in the dropdown, just append it to the end
    pageDefaultOption = { label: `${currentPage.name}`, value: currentPage?.__path };
    pageOptions.push(pageDefaultOption);
  }

  const typeDefaultOption = {
    value: 'Comment',
    label: 'Comment',
  };

  const inquiryTypeOptions: SelectOption[] = [
    typeDefaultOption,
    {
      value: 'General Information Request',
      label: 'General Information Request',
    },
    {
      value: 'Content or Data Correction',
      label: 'Content or Data Correction',
    },
    {
      value: 'Press & Media',
      label: 'Press & Media',
    },
  ];

  const stakeholderTypeOptions: SelectOption[] = [
    {
      value: 'Academia',
      label: 'Academia',
    },
    {
      value: 'Community',
      label: 'Community',
    },
    {
      value: 'Healthcare',
      label: 'Healthcare',
    },
    {
      value: 'Law',
      label: 'Law',
    },
    {
      value: 'Media',
      label: 'Media',
    },
    {
      value: 'Mental Health and Human Rights',
      label: 'Mental Health and Human Rights',
    },
    {
      value: 'Policymakers',
      label: 'Policymakers',
    },
  ];

  
  const formState: Dictionary<any> = { 
    ...values, 
    page: (values[PAGE] || pageDefaultOption),
    type: (values[TYPE] || typeDefaultOption), 
  };

  const onChange = (name: string) => (value: string | SelectOption) => { patchValues({ [name]: value }); setErrors([]); setErrorFields(without(errorFields, name)); };

  const onSubmit = async(event: React.FormEvent) => {
    event.preventDefault();
    
    const e = [];
    const ef = [];
    
    if (!token) {
      errors.push('Please check the I\'m not a robot checkbox to validate you are human');
    }

    if (trim(formState[DETAILS]) === '') {
      e.push('Please describe your contribution in the message field.');
      ef.push(DETAILS);
    }

    if (trim(formState[NAME]) === '') {
      e.push('Please enter your name.');
      ef.push(NAME);
    }

    if (trim(formState[EMAIL]) !== '' && !EmailValidator.validate(formState[EMAIL])) {
      e.push('Please check that your email address is VALID');
      ef.push(EMAIL);
    }

    if (trim(formState[AGREE]) === '') {
      e.push(t("Please confirm you agree with our terms & conditions"));
      ef.push(AGREE);
    }

    setErrorFields(ef);
    setErrors(e);

    if (e.length) {
      return false;
    }


    const data = { ...formState, 'g-recaptcha-response': token };
    form.startSubmit();
    const response = await onSubmitNetlifyForm(FORM_NAME, currentPage.__path, data);
    setToken('');
    form.endSubmit();
  };

  const onCaptchaChange = (token: any) => {
    setToken(token);
  };

  const isSubmitDisabled = !token;
  const hasErrors = !!errors.length;

  const SITE_RECAPTCHA_KEY = useSiteRecaptchaKey();

  const introductionText = {
    text: INTRO_FORM_TEXT,
  }

  const agreeText = sprintf(t("I agree to the [Terms & Conditions](%s)"), TERMS_PAGE_PATH)

  return (
    <My.Container ref={ref} className={cls.name(className)}>
      {
        form.isSubmitting && 
        <My.Loader>Please wait...</My.Loader>
      }
      {
        (form.isSubmitted) ?
        <My.Thankyou>
          <My.ThankyouMessage>
          <p>
          We've received your feedback.
          </p>
          <p>
          Thanks for your contribution!
          </p>
          </My.ThankyouMessage>
          <My.DoneButton onClick={() => { form.reset(); form.hide(); }}>Done</My.DoneButton>
        </My.Thankyou> :
        <My.Form name={FORM_NAME} onSubmit={onSubmit} method="post" data-netlify="true" data-netlify-recaptcha="true">
          <input type="hidden" name="form-name" value={FORM_NAME} />
          <My.Introduction entity={introductionText} />
          <My.InquiryTypeField>
            <My.InquiryType 
              onChange={onChange(TYPE)}
              options={inquiryTypeOptions}
              value={formState[TYPE]} 
            />
          </My.InquiryTypeField>

          <My.PageField>
            <My.Page
              isClearable={false}
              onChange={onChange(PAGE)}
              value={formState[PAGE]}
              options={pageOptions}
              
            />
          </My.PageField>

          <My.DetailsField>
            <My.Details hasError={includes(errorFields, DETAILS)} onChange={onChange(DETAILS)}>{formState[DETAILS] || ''}</My.Details>
          </My.DetailsField>

          <My.NameField>
            <My.Name hasError={includes(errorFields, NAME)} onChange={onChange(NAME)} value={formState[NAME] || ''} />
          </My.NameField>

          <My.EmailField>
            <My.Email hasError={includes(errorFields, EMAIL)} onChange={onChange(EMAIL)} value={formState[EMAIL] || ''} />
          </My.EmailField>

          <My.AgreeToContact label={sprintf(t('I Agree To Be Contacted At This Email Address'), SITE_OWNER_NAME)} onChange={onChange(BE_CONTACTED)} checked={formState[BE_CONTACTED] === 'yes'} />

          <My.CompanyField>
            <My.Company hasError={includes(errorFields, COMPANY)} onChange={onChange(COMPANY)} value={formState[COMPANY] || ''} />
          </My.CompanyField>

          
          <My.StakeholderTypeField>
            <My.StakeholderType 
              onChange={onChange(STAKEHOLDER_TYPE)}
              options={stakeholderTypeOptions}
              value={formState[STAKEHOLDER_TYPE]} 
            />
          </My.StakeholderTypeField>

          <My.Agree onChange={onChange(AGREE)} valid={!includes(errorFields, AGREE)} checked={formState[AGREE] === 'yes'}>
          <MarkdownContent 
            text={agreeText} 
            embedComponents={{
              a: {
                component: MarkdownContentLinkTargetBlank
              }
            }}
          />
          </My.Agree>

          {
            SITE_RECAPTCHA_KEY && 
            <ReCAPTCHA
              sitekey={SITE_RECAPTCHA_KEY}
              onChange={onCaptchaChange}
            />
          }
          {
            hasErrors && 
            <My.Errors items={errors}>
            Sorry, we need a bit more information:
            </My.Errors>
          }
          <My.SubmitButton disabled={isSubmitDisabled} />
        </My.Form>
      } 
      
    </My.Container>
  )
};

ContributeForm.defaultProps = ContributeFormDefaultProps;
ContributeForm.cls = cls;