import React from 'react';
import styled, { css } from 'styled-components';
import { PageLayoutComponent } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { PageLayoutStandard } from '@shapeable/web';

// -------- Styles -------->

const LayoutStyles = breakpoints({
  base: css`
    /* override title styles via BEM here to affect spacing */ 
    .shp--page-layout-standard__title {
      max-width: 900px;
      font-weight: 500;
    }
  `,
  tablet: css`
  `,
  desktop: css`
  `
});

// -------- Components -------->

const My = {
  Layout: styled(PageLayoutStandard)`${LayoutStyles}`,
};

export const PageLayoutDefault: PageLayoutComponent = (props) => {
  const { className, entity, slices } = props;

  return (
    <My.Layout 
      entity={entity}
      slices={slices}
      headerProps={{ aspectRatio: { base: 4 / 3, desktop: 21 / 9 }, showOverlayGradient: true, variant: 'overlay' }} 
      className={className}
    />
  )
};
