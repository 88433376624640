import React from 'react';
import { useContext } from 'react';
import { Classable, HasChildren, MenuItem, Shapeable } from '@shapeable/types';
import styled, { css } from 'styled-components';
import { breakpoints, theme } from '@shapeable/theme';
import { PageContextDefinition, 
  ViewProvider, useAuth, useReady, 
  useModal, LinkButtonReplaceable, useContributeForm, 
  UserMenuProvider, 
} from '@shapeable/ui';
import { MapState, MapConfig, MapProvider, EntityMap } from '@shapeable/maps';
import { GlobeIconGlyph, TickIcon } from '@shapeable/icons';
import { LayoutShell, SiteFooterLayout, WebProvider, WebConfig } from '@shapeable/web';
import * as pageLayouts from './page-layouts';
import * as sliceLayouts from './slice-layouts';
import { LANG_STRINGS } from '../lang';
import { COLOR_1_TO_5_5, FOOTER_WARM_GREY, LayoutStyles, YELLOW, themeOverrides } from '../theme';
import { CountriesProvider } from '../gatsby/providers/countries-provider';
import { PostsProvider } from '../gatsby/providers/posts-provider';
import { VideosProvider } from '../gatsby/providers/videos-provider';
import { FeedEntriesProvider } from '../gatsby/providers/feed-entries-provider';
import { TypeIcon } from './icons/type-icon';
import { LegalSystemIcon } from './icons/legal-system-icon';
import { CHARCOAL_GREY, DEFAULT_MAP_ENTITY_TYPE_NAMES } from '../data';
import { CountryTooltip } from './entities/country-tooltip';
import { FahWidgetModal } from './elements/fah-widget-modal';
import { Helmet } from 'react-helmet';
import { LinkButton } from './elements/link-button';
import { MethodologiesProvider } from '../gatsby/providers/methodologies-provider';
import { LegalSystemsProvider } from '../gatsby/providers/legal-systems-provider';
import { ContributeFormPanel } from './elements/contribute-form-panel';
import { UserMenu } from './elements/user-menu';
import { useActivePerson } from '../hooks/use-active-person';
import { TermsAgreeDialog } from './elements/terms-agree-dialog';
import { CountryCard } from './entities/country-card';
import { EconomyStatusIcon } from './entities/economy-status-icon';
import { EntityMapPanel } from '@shapeable/maps';
import { SidebarButtons } from './elements/sidebar-buttons';
import { useCountries } from '../hooks/use-countries';


const initialMapState: MapState = {
  isVisible: false,
  isInitialized: false,
  zoom: 2,
  entityTypeNames: DEFAULT_MAP_ENTITY_TYPE_NAMES,
  latitude: -5.441022303718128,
  longitude: 117.59765625000352,
};

export type LayoutPropTypes = Classable & HasChildren & {
  path?: string;
  data?: any;
  pageContext?: PageContextDefinition;
};

const LayoutDefaultProps: LayoutPropTypes = {
};

type ContributeFormPanelProps = {
  isVisible?: boolean;
};

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    ${LayoutStyles};
    background: #000;
  `,
});

const FooterStyles = breakpoints({
  base: css`
  `,
});

const BodyStyles = breakpoints({
  base: css`
    flex-grow: 1;
    background: #FFF;
  `,
});


const TickCircleStyles = breakpoints({
  base: css`
  ${theme.FILL(COLOR_1_TO_5_5)}
  `,
});

const FahWidgetStyles = breakpoints({
  base: css`
    
  `,
});

const ContributeFormStyles = breakpoints({
  base: css`
    position: fixed;
    left: 100%;
    z-index: 800;
    transition: left 0.3s;
    width: 100%;
    height: 100%;
    top: 0;

    ${({ isVisible }: ContributeFormPanelProps) => isVisible && css`
      left: 0%;
    `}
  `,
  tablet: css`

  `,
  desktop: css`
    width: 50%;

    ${({ isVisible }: ContributeFormPanelProps) => isVisible && css`
      left: 50%;
    `}
  `,
});

const TermsDialogStyles = breakpoints({
  base: css`
    
  `,
});

const MapStyles = breakpoints({
  base: css`
    
  `,
});




// -------- Components -------->

const My = {
  Container: styled(LayoutShell)`${ContainerStyles}`,
  Body: styled.div`${BodyStyles}`,
  Footer: styled(SiteFooterLayout)`${FooterStyles}`,
  TickCircle: styled(TickIcon)`${TickCircleStyles}`,
  FahWidget: styled(FahWidgetModal)`${FahWidgetStyles}`,
  ContributeForm: styled(ContributeFormPanel)<ContributeFormPanelProps>`${ContributeFormStyles}`,
  Map: styled(EntityMapPanel)`${MapStyles}`,
  TermsDialog: styled(TermsAgreeDialog)`${TermsDialogStyles}`,
};

export const Layout: React.FC<LayoutPropTypes> = (props) => {
  const { className, children } = props;

  // const [contributeFormState] = useContext(ContributeFormContext);
  // const isFormVisible = contributeFormState.isFormVisible;

  const contributeForm = useContributeForm();
  const modal = useModal();

  // load this as soon as the auth state changes, so we avoid country profile menu items being delayed 
  const { person, hasAgreedToTerms } = useActivePerson();
  const { isAuthenticated } = useAuth();

  const onClickHandlerForMenuItem = (item: MenuItem) => {
    if (item.slug === 'contact') {
      return () => {
        contributeForm.show();
      };
    }
  }

  const onHeaderButtonClick = (slug: string) => {
    if (slug === 'are-you-in-crisis-1') {
      modal.show(<My.FahWidget />, { color: '#000000', title: 'Find a Helpline', opacity: 0.8 });
    }
  };

  const mapConfig: MapConfig = {
    initialMapState,
    entityTypeMapMarkers: {
      Economy: EconomyStatusIcon,
    },
    availableEntityTypeNames: ['Economy'],
    items: () => useCountries(),
  };

  const webConfig: WebConfig = {
    layouts: { pageLayouts, sliceLayouts, defaultPageLayout: pageLayouts.PageLayoutDefault },
    langStrings: LANG_STRINGS,
    menuBar: {
      isInverted: true,
    },
    sidebar: {
      isAvailable: () => true,
      showFullscreenButton: true,
      color: CHARCOAL_GREY, 
      width: 65,
      buttons: <SidebarButtons />
    },
    cookieBannerFeaturesList: 
      <ul>
        <li><b>Mapping Cookie:</b> enables the mapping feature (Mapbox)</li>
        <li><b>Contact Form Cookie:</b> enables the form feature (Netlify).</li>
        <li><b>Country Microsite Login:</b> enables dashboard login feature (Auth0)</li>
      </ul>,
    header: {
      onHeaderButtonClick,
    },
    footer: {
      onClickHandlerForMenuItem,
    },
    theme: themeOverrides,

    entity: {
      labels: {
        Economy: 'Country',
        
      },
      icons: {
        PostType: TypeIcon,
        FeedEntryType: TypeIcon,
        LegalSystem: LegalSystemIcon,
        Language: GlobeIconGlyph,
        Organisation: My.TickCircle,
      },
      cards: {
        Economy: CountryCard
      }, 
      tooltips: {
        Economy: CountryTooltip,
      },
      dataProviders: [
        CountriesProvider, FeedEntriesProvider, LegalSystemsProvider,
        MethodologiesProvider, PostsProvider, VideosProvider
      ]
     },
     componentProps: {
        MoreDivider: {
          minimise: true,
        },
        TaskItemLayout: {
          hideContent: false,
        },
     }
  };

  return (
    <LinkButtonReplaceable.Provider value={LinkButton}>
    <WebProvider value={webConfig}>
    <UserMenuProvider value={{
      component: UserMenu,
      imageUrl: person?.photo?.url,
    }}>
    <ViewProvider>
    <MapProvider value={mapConfig}>
      <My.Container
        className={className}
        includeTooltip
        tooltipProps={{
          backgroundColor: YELLOW,
        }}
      >
        {
          isAuthenticated && !hasAgreedToTerms &&
          <My.TermsDialog />
        }
        <My.Map />
        <My.ContributeForm isVisible={contributeForm.isVisible} />
        <Helmet>
          <script src="https://lifeline-intl.findahelpline.com/widget.min.js"></script>
        </Helmet>
        <My.Body>
          {children}
        </My.Body>
        <My.Footer backgroundColor={FOOTER_WARM_GREY} />
      </My.Container>
    </MapProvider>
    </ViewProvider>
    </UserMenuProvider>
    </WebProvider>
    </LinkButtonReplaceable.Provider>
  );
  
  
};

Layout.defaultProps = LayoutDefaultProps;

