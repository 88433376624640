import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, EntityWithDerivableCoordinates, HasChildren, Shapeable } from '@shapeable/types';
import { Economy } from '@shapeable/lli-types';
import { breakpoints, theme } from '@shapeable/theme';
import { CellLabel, EntityMultiValueList, Icon, PlainLinkButton, useEntity, useLang, useLink,  } from '@shapeable/ui';
import { IndicatorValueBooleanColors, IndicatorValueBooleanIcons, IndicatorValueBooleanLabels, IndicatorValueCanonicalValue } from './indicator-value-boolean';
import { get, sortBy, map, includes } from 'lodash';
import { BRIGHT_ORANGE } from '../../data';
import { ChevronRightIcon } from '@shapeable/icons';
import { useMapUtils } from '@shapeable/maps';
import { classNames } from '@shapeable/utils';
const cls = classNames('countries-tooltip');

// -------- Types -------->

export type CountryTooltipProps = Classable & HasChildren & { 
  entity?: Economy;
  onLinkClick?: React.MouseEventHandler;
};

export const CountryTooltipDefaultProps: Omit<CountryTooltipProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

type ValueProps = {
  hasLink: boolean;
}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    padding: ${theme.UNIT(5)};
    .shp--cell-header {
      display: none;
    }
  `,
});

const BodyStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.UNIT(2)};
    ${theme.H_DOT_BG()};
  `,
});

const TitleContainerStyles = breakpoints({
  base: css`
    padding-bottom: ${theme.UNIT(4)};
    ${theme.H_DOT_BG_END()}

  `,
});

const TitleStyles = breakpoints({
  base: css`
    font-family: ${theme.FONT('sans')};
    font-size: ${theme.FONT_SIZE(18)};
    font-weight: 500;
    line-height: 1.25em;
    margin-bottom: 0;
    color: ${theme.COLOR('dark')};
    background: none;
    margin-top: 0;

  `,
});

const LegalSystemStyles = breakpoints({
  base: css`
    
  `,
});

const LabelStyles = breakpoints({
  base: css`
    margin: ${theme.UNIT(2)} 0;
    font-size: ${theme.FONT_SIZE(12)};
  `,
});

const PopulationLabelStyles = breakpoints({
  base: css`
    font-weight: 600;
  `,
});



const DecrimStatusStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: ${theme.UNIT(3)};
    ${theme.H_DOT_BG_END()}
  `,
});

const PopulationItemStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
    padding-bottom: ${theme.UNIT(3)};
  `,
});

const PopulationStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: row;
    gap: ${theme.UNIT(10)};
    ${theme.H_DOT_BG_END()}
  `,
});

const ValueLabelStyles = breakpoints({
  base: css`
    text-transform: uppercase;
    font-size: ${theme.FONT_SIZE(14)};
  `,
});

const LinkStyles = breakpoints({
  base: css`
    margin-top: ${theme.UNIT(4)};
    font-weight: 500;
    font-size: ${theme.FONT_SIZE(14)};
    align-items: center;
    display: flex;
    font-family: ${theme.FONT('sans')};
    color: ${theme.COLOR(BRIGHT_ORANGE)};
    ${theme.FILL('link')};
    &:hover {
      ${theme.FILL('link-hover')};
    }
  `,
});

const IconStyles = breakpoints({
  base: css`
   margin-right: ${theme.UNIT(2)};
  `,
});



const DetailsIconStyles = breakpoints({
  base: css`
    width: 24px;
    height: 24px;
    margin-right: ${theme.UNIT(2)};
    ${theme.FILL(BRIGHT_ORANGE)};
  `,
});



// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`, 
    Body: styled.div`${BodyStyles}`,
    TitleContainer: styled.div`${TitleContainerStyles}`,
    Title: styled.span`${TitleStyles}`,
    Label: styled(CellLabel)`${LabelStyles}`,

    DecrimStatus: styled.div`${DecrimStatusStyles}`,
      ValueLabel: styled.span`${ValueLabelStyles}`,
      Icon: styled(Icon)`${IconStyles}`,
    Population: styled.div`${PopulationStyles}`,
      PopulationLabel: styled(CellLabel)`${PopulationLabelStyles}`,
      PopulationItem: styled.div`${PopulationItemStyles}`,

    LegalSystems: styled(EntityMultiValueList)`${LegalSystemStyles}`,
    Link: styled(PlainLinkButton)`${LinkStyles}`, 
      DetailsIcon: styled(ChevronRightIcon)`${DetailsIconStyles}`,

};

export const CountryTooltip: Shapeable.FC<CountryTooltipProps> = (props) => {
  const { className, children, onLinkClick } = props;
  const entity = useEntity(props.entity);

  const { name, legalSystems = [], indicatorValues = [] } = entity;

  const hasIndicatorValues = !!indicatorValues.length;

  const hasLegalSystems = !!legalSystems.length;

  const { navigate } = useLink();
  const { hideMap } = useMapUtils();

  const t = useLang();

  const hasLink = true;
  
  const valueOnClick: React.MouseEventHandler = hasLink && ((event) => {
    if (onLinkClick) {
      onLinkClick(event);
    }
    navigate(entity.path);
    hideMap({ deselect: true });
  });



  return (
   <My.Container className={cls.name(className)}>
    <My.TitleContainer>
      <My.Title>{name}</My.Title>
    </My.TitleContainer>
    
    {
          hasIndicatorValues &&
            indicatorValues.map((indicatorValue, index) => {
              const { value, indicator, numericValue } = indicatorValue;
              const canonicalValue: IndicatorValueCanonicalValue = !value ? 'UNCERTAIN' : includes(['YES', 'Y'], value.toUpperCase()) ? 'YES' : 'NO';
              const icon = IndicatorValueBooleanIcons[canonicalValue];
              const color = IndicatorValueBooleanColors[canonicalValue];
              const label = IndicatorValueBooleanLabels[canonicalValue];
    
              return (
                <>
                  {
                    indicator.slug === 'is-suicide-decriminalised' && (
                      <>
                        <My.Label>{t("Is Suicide Decriminalised")}</My.Label>
                        <My.DecrimStatus>
                          <My.Icon size={24} color={color}>{icon}</My.Icon>
                          <My.PopulationLabel>{label}</My.PopulationLabel>
                        </My.DecrimStatus>
                      </>
                    )
                  }
                </>
              );
            })
        }
        {
          hasIndicatorValues && (
            <>
              {
                <My.Label>{t("Population")}</My.Label>}
                <My.Population>
                  {sortBy(indicatorValues, ['indicator.slug']).map((indicatorValue, index) => {
                    const { indicator, numericValue } = indicatorValue;
                    const value = numericValue.toLocaleString();
                    return (
                      <>
                        {
                          (indicator.slug === 'population-millions' && numericValue > 0) && (
                            <My.PopulationItem>
                              <My.PopulationLabel>{t("Total")}</My.PopulationLabel>
                              <My.ValueLabel>{value}M</My.ValueLabel>
                            </My.PopulationItem>
                          )
                        }
                        {
                          (indicator.slug === 'population-in-need-of-support-per-year' && numericValue > 0) && (
                            <My.PopulationItem>
                              <My.PopulationLabel>{t("In Need")}</My.PopulationLabel>
                              <My.ValueLabel>{value}</My.ValueLabel>
                            </My.PopulationItem>
                          )
                        }
                        
                      </>
                    );
                  })
                }
              </My.Population>
            </>
          )
        }
      {
        hasLegalSystems && (
          <>
            <My.Label>{t("Legal and Religious System:")}</My.Label>
            <My.LegalSystems items={legalSystems} />
          </>
        )
      }
      {
        <My.Link onClick={valueOnClick}><My.DetailsIcon />{t('View Country Details')}</My.Link>
      }
   </My.Container>
  )
  
};

CountryTooltip.defaultProps = CountryTooltipDefaultProps;
CountryTooltip.cls = cls;