import { graphql, useStaticQuery } from "gatsby";
import * as React from "react";
import { platformProviderValue, PostsProvider as Provider } from "@shapeable/ui";


const QUERY = graphql`
  query PostsQuery {
    platform {
      allPosts { 
        id name path slug __typename
        _schema { label pluralLabel }
        # intro { text }
        # content { text }
        title
        openGraph { image { thumbnails { full { url } } } }
        legalSystems { id name slug __typename _schema { label pluralLabel } }
        methodologies { id name slug __typename _schema { label pluralLabel } }
        type { id name slug __typename }
        published
        topics { 
          id name slug __typename _schema { label pluralLabel }
          openGraph { id image { id url } }
        }
        trends { 
          id name slug __typename _schema { label pluralLabel }
          openGraph { id image { id url } }
        }
        people {
          id name slug path 
          organisation { id name }
          linkedin
          position
          photo { id url type static { childImageSharp { gatsbyImageData } } }
        }
        economies {
          id name slug path __typename _schema { label pluralLabel }
          openGraph { title description { plain } image { thumbnails { full { url } } } }
          
        }
        authors {
          openGraph { id image { id url } }
          _schema { label pluralLabel }
          id name slug path
          organisation { id name }
          linkedin
          openGraph { id image { id url } }
          photo { id url type static { childImageSharp { gatsbyImageData } } }
        }
        organisationAuthors {
          id name slug path url
          logoInverted { type url }
          logo { type url }
        }	
      }
    }
  }
`

export const PostsProvider: React.FC<any> = ({ children }) => {
  return <Provider value={ platformProviderValue( useStaticQuery(QUERY), 'allPosts' ) }>{children}</Provider>;
}