import React from 'react';
import styled, { css } from 'styled-components';
import { PageLayoutComponent, TContentNode } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { WaitMessage, useEntity, useLink, EntityGrid, SliceLayoutBoundary, useLang, ContentNode, useAuth } from '@shapeable/ui';
import { useActivePerson } from '../../hooks/use-active-person';
import { template } from 'lodash';
import { ORANGE } from '../../theme';
import { PageLayoutStandard } from '@shapeable/web';
import { classNames } from '@shapeable/utils';
const cls = classNames('page-layout-members');

// -------- Child Component Props -------->

type ContainerProps = {

};

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`

  `,
});

const WaitMessageStyles = breakpoints({
  base: css`
    margin-top: ${theme.UNIT(20)};
  `,
});


const BodyStyles = breakpoints({
  base: css`
    
  `,
});

const GridStyles = breakpoints({
  base: css`
    
  `,
});

const ContentStyles = breakpoints({
  base: css`
    h1 {
      color: ${ORANGE};
      font-weight: 500;
    }
  `,
});





// -------- Components -------->

const My = {
  Container: styled(PageLayoutStandard)<ContainerProps>`${ContainerStyles}`,
    WaitMessage: styled(WaitMessage)`${WaitMessageStyles}`,

    Body: styled.div`${BodyStyles}`,
      Content: styled(ContentNode)`${ContentStyles}`, 
      Grid: styled(EntityGrid)`${GridStyles}`

};

export const PageLayoutMembers: PageLayoutComponent = 
(props) => {
  const { className, children, slices } = props;
  const entity = useEntity(props.entity);

  // need to load in the current member
  const { user } = useAuth();
  const { isLoading, person, isAvailable } = useActivePerson();

  const { navigate } = useLink();

  if (person?.memberOfEconomies?.length === 1 && isAvailable) {
    const path = person?.memberOfEconomies[0]?.path;

    if (path) {
      navigate(`${path}#view=activity`);
    }
  }

  const items = (person?.memberOfEconomies || []).map(economy => {
    return {
      ...economy,
      path: `${economy.path}`,
      _schema: {
        label: 'Country Profile',
        pluralLabel: 'Country Profiles'
      }
    }
  });

  const t = useLang();
  const introTemplate = template(t(`# Country Dashboard 

Welcome <%= name %>. You have access to the following country profiles:
`));

  const intro: TContentNode = {
    text: introTemplate({ name: user?.givenName || person?.name })
  };

  // otherwise, render a country grid with the countries the member has access to 
  return (
    <My.Container slices={slices} entity={entity} className={cls.name(className)}>

    {
      isLoading ?
      <WaitMessage /> :
      <My.Body>
        <SliceLayoutBoundary boundary='content' verticalPadding='content'>
        <My.Content entity={intro} />
        </SliceLayoutBoundary>  
        <SliceLayoutBoundary boundary='content-bleed'>
        <My.Grid items={items} />
        </SliceLayoutBoundary>
      </My.Body>
    }
    {children}
    </My.Container>
  )
};