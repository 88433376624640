import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react'; // tslint:disable-line
import { platformProviderValue } from '@shapeable/web';
import { MethodologiesProvider as Provider } from '../../components/context/methodologies-context';

const METHODOLOGIES_QUERY = graphql`
  query MethodologiesQuery {
    platform {
      allMethodologies {
        id name path label slug __typename _schema { label pluralLabel }
      }
    }
  }
`

export const MethodologiesProvider: React.FC<any> = ({ children }) => {
  return <Provider value={platformProviderValue(useStaticQuery(METHODOLOGIES_QUERY), 'allMethodologies')}>{children}</Provider>;
}