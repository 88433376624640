import React from 'react';
import styled, { css } from 'styled-components';
import { PageLayoutComponent } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { FeedEntriesProvider, useEntity, useFeedEntries } from '@shapeable/ui';
import { SliceLayoutKnowledgeHub } from '../slice-layouts';
import { map } from 'lodash';
import { SiteHeaderLayout, SiteHeaderProvider } from '@shapeable/web';
import { classNames } from '@shapeable/utils';
const cls = classNames('page-layout-knowledge-hub');


// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    .shp--tab {
      font-size: ${theme.FONT_SIZE(15)};
    }
  `,
  tablet: css`
    .shp--tab {
      font-size: ${theme.FONT_SIZE(17)};
    }
  `
});

// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,

};

export const PageLayoutKnowledgeHub: PageLayoutComponent = 
(props) => {
  const { className, children, slices } = props;
  const entity = useEntity(props.entity);

  const feedEntries = map(useFeedEntries(), feedEntry => {
    return {
      ...feedEntry,
      types: [
        ...((feedEntry.types || []).map(type => ({ 
          ...type, 
          _schema: {
            label: 'Reference Type', 
            pluralLabel: 'Reference Types' 
          }
        })))
      ]
    }
  });
  
  return (
    <SiteHeaderProvider value={{ variant: 'dark' }}>
    <FeedEntriesProvider value={feedEntries}>
    <My.Container className={cls.name(className)}>
      <SiteHeaderLayout />
      {slices}
      <SliceLayoutKnowledgeHub entity={entity} />
    </My.Container>
    </FeedEntriesProvider>
    </SiteHeaderProvider>
  )
};