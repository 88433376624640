import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable, ImageEntity as ImageEntityType} from '@shapeable/types';
import { Economy } from '@shapeable/lli-types';
import { breakpoints, theme } from '@shapeable/theme';
import { Card, CellHeader, CellLabel, EntityCard, EntityCardProps, EntityMultiValueList, Icon, ImageEntity, useEntity, useLang, useLink } from '@shapeable/ui';
import { get, includes } from 'lodash';
import { IndicatorValueBooleanColors, IndicatorValueBooleanIcons, IndicatorValueBooleanLabels, IndicatorValueCanonicalValue } from './indicator-value-boolean';
import { sortBy } from 'lodash';
import { classNames, coalesce } from '@shapeable/utils';
const cls = classNames('country-card');

// -------- Types -------->

export type CountryCardProps = Classable & HasChildren & Omit<EntityCardProps, 'entity'> & { 
  entity?: Economy;
};

export const CountryCardDefaultProps: Omit<CountryCardProps, 'entity'> = {
  imageAspectRatio: { 
    base: 16 / 9, 
    landscape: 21 / 9, 
    tablet: 16 / 9,
  }
};

// -------- Child Component Props -------->

type ContainerProps = {

}

type TitleProps = {
  hasLink: boolean;
};

type ImageProps = {
  hasLink?: boolean;
};

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  
  `,
});

const BodyStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.UNIT(3)};

    font-weight: inherit;
    padding-top: ${theme.UNIT(3)};
    padding-bottom: ${theme.UNIT(3)};
    margin-top: ${theme.UNIT(3)};
    ${theme.H_DOT_BG()};
  `,
});

const ImageStyles = breakpoints({
  base: css`
    
    ${({ hasLink }: ImageProps ) => hasLink && css`
      transition: filter 0.2s;

      &:hover {
        filter: brightness(1.2);
      }
    `}

    img {
      object-fit: cover;
    }
  `,
});

const TitleStyles = breakpoints({
  base: css`
    font-family: ${theme.FONT('sans')};
    font-size: ${theme.FONT_SIZE(18)};
    font-weight: 500;
    line-height: 1.25em;
    margin-bottom: 0;
    color: ${theme.COLOR('dark')};
    background: none;
    margin-top: 0;
    margin-bottom: ${theme.UNIT(2)};

    ${({ hasLink }: TitleProps ) => hasLink && css`
      &:hover {
        color: ${theme.COLOR('link-hover')};
      }
    `}
  `,
});

const LabelStyles = breakpoints({
  base: css`
    font-size: ${theme.FONT_SIZE(15)};
    line-height: 1.75em;
    font-weight: 400;
    color: ${theme.COLOR('text')};
  `,
});

const ContentStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${theme.UNIT(2)};
    padding-bottom: ${theme.UNIT(3)};
    ${theme.H_DOT_BG_END()}
  `,
});

const IconStyles = breakpoints({
  base: css`
   flex-shrink: 0;
  `,
});

const LegalSystemStyles = breakpoints({
  base: css`
    
    ;
  `,
});

const ValueLabelStyles = breakpoints({
  base: css`
    text-transform: uppercase;
  `,
});

const PopulationItemStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${theme.UNIT(3)};
    padding-bottom: ${theme.UNIT(3)};
  `,
});

const PopulationStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: row;
    gap: ${theme.UNIT(8)};
    ${theme.H_DOT_BG_END()}
  `,
});


// -------- Components -------->

const My = {
  Card: styled(Card)<ContainerProps>`${ContainerStyles}`,
    Body: styled.div`${BodyStyles}`,
      Image: styled(ImageEntity)<ImageProps>`${ImageStyles}`,
      Title: styled.span<TitleProps>`${TitleStyles}`,
      Content: styled.div`${ContentStyles}`,
        Label: styled(CellLabel)`${LabelStyles}`,
        ValueLabel: styled.b`${ValueLabelStyles}`,
        // Value: styled.span`${ValueStyles}`,
      Population: styled.div`${PopulationStyles}`,
      PopulationItem: styled.div`${PopulationItemStyles}`,
      
      
      Icon: styled(Icon)`${IconStyles}`,
      LegalSystem: styled(EntityMultiValueList)`${LegalSystemStyles}`,
};

export const CountryCard: Shapeable.FC<CountryCardProps> = (props) => {
  const { className, children, imageAspectRatio, ...rest } = props;
  const entity = useEntity(props.entity);

  const t = useLang();

  const { name, indicatorValues = [], legalSystems = [] } = entity;

  const hasIndicatorValues = !!indicatorValues.length;
  const hasLegalSystems = !!legalSystems.length;
  

  let image: ImageEntityType = coalesce(entity, ['banner', 'logo', 'photo']);

  const { Link } = useLink();

  const href = props.href === undefined ? entity.path : props.href;

  const hasTitleLink = !!href && includes(['title', 'title-image'], 'title-image');

  const titleElement = <My.Title hasLink={hasTitleLink}>{entity.name}</My.Title>;

  if (!image && entity.openGraph && entity.openGraph.image) {
    image = {
      id: `entity-image-${entity.id}`,
      image: entity.openGraph.image,
    }
  }

  if (!image && get(entity, 'image')) {
    image = {
      id: `entity-image-${entity.id}`,
      image:  get(entity, 'image'),
    }
  }

  const imageLinkStyle = {
    height: '100%',
    width: '100%',
    display: 'block',
  };

  return (
    <My.Card {...rest} className={cls.name(className)} imageAspectRatio={imageAspectRatio} image={<Link style={imageLinkStyle} href={href}><My.Image hasLink entity={image} /></Link>}>
      {hasTitleLink
        ? <Link style={{ textDecoration: 'none' }} href={href}>{titleElement}</Link>
        : titleElement
      }
      <My.Body>
        {
          hasIndicatorValues &&
            indicatorValues.map((indicatorValue, index) => {
              const { value, indicator, numericValue } = indicatorValue;
              const canonicalValue: IndicatorValueCanonicalValue = !value ? 'UNCERTAIN' : includes(['YES', 'Y'], value.toUpperCase()) ? 'YES' : 'NO';
              const icon = IndicatorValueBooleanIcons[canonicalValue];
              const color = IndicatorValueBooleanColors[canonicalValue];
              const label = IndicatorValueBooleanLabels[canonicalValue];
    
              return (
                indicator.slug === 'is-suicide-decriminalised' && (
                  <React.Fragment key={indicator.id}>
                    <My.Label>{t("Is Suicide Decriminalised")}</My.Label>
                    <My.Content>
                      <My.Icon size={24} color={color}>{icon}</My.Icon>
                      <My.ValueLabel>{label}</My.ValueLabel>
                    </My.Content>
                  </React.Fragment>
                )
              );
            })
        }
        {
          hasIndicatorValues && (
            <>
              {
                <My.Label>{t("Population")}</My.Label>}
                <My.Population>
                  {sortBy(indicatorValues, ['indicator.slug']).map((indicatorValue, index) => {
                    const { indicator, numericValue } = indicatorValue;
                    const value = numericValue.toLocaleString();
                    return (
                      <React.Fragment key={indicatorValue.id}>
                        {
                          (indicator.slug === 'population-millions' && numericValue > 0) && (
                            <My.PopulationItem>
                              <My.ValueLabel>{t("Total")}</My.ValueLabel>
                              <My.Label>{value}M</My.Label>
                            </My.PopulationItem>
                          )
                        }
                        {
                          (indicator.slug === 'population-in-need-of-support-per-year' && numericValue > 0) && (
                            <My.PopulationItem>
                              <My.ValueLabel>{t("In Need")}</My.ValueLabel>
                              <My.Label>{value}</My.Label>
                            </My.PopulationItem>
                          )
                        }
                        
                      </React.Fragment>
                    );
                  })
                }
              </My.Population>
            </>
          )
        }
        {/* {
          hasLegalSystems && (
            <>
              <My.Label>{t("Legal and Religious System:")}</My.Label>
              <My.Content>
                <My.LegalSystem items={legalSystems} />
              </My.Content>
            </>
          )
        } */}
      </My.Body>
      {children}
    </My.Card>
  );
};

CountryCard.defaultProps = CountryCardDefaultProps;
CountryCard.cls = cls;