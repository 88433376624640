import React, { useState } from 'react';
import { Classable, Dictionary, HasChildren, Shapeable } from '@shapeable/types';
import { Economy } from '@shapeable/lli-types';
import styled, { css } from 'styled-components';
import { breakpoints, theme } from '@shapeable/theme';
import { ErrorList, Field, useForm, FormLoader, Input, Select, TextArea, usePage, useLink, useLang,
Button, useActiveLang, MarkdownContent, MarkdownContentLinkTargetBlank, useSiteRecaptchaKey, useTermsPagePath, usePrivacyPagePath, useSiteOwnerName, SelectOption, useReady, InputCheckbox } from '@shapeable/ui';
import { classNames, onSubmitNetlifyForm } from '@shapeable/utils';
import { DottedChevronDownIcon } from '@shapeable/icons';
import EmailValidator from 'email-validator';
import { includes, isPlainObject, trim, without } from 'lodash';
import ReCAPTCHA from 'react-google-recaptcha';
import validUrl from 'valid-url';
import { sprintf } from 'sprintf-js';
import { ORANGE } from '../../theme';
const cls = classNames('contact-form');


// -------- Types -------->

export type ContactFormProps = Classable & HasChildren & {
  beforeSubmit?: () => void;
  afterSubmit?: () => void;
  types?: string[];
  typesRequired?: boolean;
  typesLabel?: string;
  typesRequiredError?: string;
  associatedCountries?: Economy[];
  showMembershipCheckbox?: boolean; 
  showSubscribeCheckbox?: boolean; 
}

export const ContactFormDefaultProps: ContactFormProps = {
  showMembershipCheckbox: true,
  showSubscribeCheckbox: true,
  associatedCountries: [],
  typesRequired: true,
  typesLabel: 'What would you like to contact us about? (Required)',
  typesRequiredError: 'Please select the reason you are contacting us.',
  types: [],
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  `,
});

const FieldStyles = breakpoints({
  base: css`
    align-self: stretch;
    label {
      color: ${theme.COLOR('text')};
    }
  `,
});

const FormStyles = breakpoints({
  base: css`
    padding-bottom: ${theme.UNIT(3)};
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
});

const BaseInputStyles = breakpoints({
  base: css`
    border: 2px dotted ${theme.COLOR('secondary')};
    color: ${theme.COLOR('primary')};

    &:focus {
      outline: none;
      border: 2px dotted ${theme.COLOR('link-hover')};
    }

    ${({ hasError }: any ) => hasError && css`
      outline: none;
      border-color: red;
    `}
  `,
});



const TextAreaStyles = breakpoints({
  base: css`
    width: 100%;
    height: 150px;
    padding: ${theme.UNIT(4)};
    ${BaseInputStyles};
  `,
});

const SelectStyles = breakpoints({
  base: css`
    width: 100%;
    font-size: ${theme.FONT_SIZE(14)};

    .shp--select__control {
      border: 2px dotted ${theme.COLOR('secondary')};

      &:hover {
        border: 2px dotted ${theme.COLOR('secondary')};
      }
    }
  `,
});

const InputStyles = breakpoints({
  base: css`
    width: 100%;
    padding: ${theme.UNIT(4)};
    color: ${theme.COLOR('primary')};
    ${BaseInputStyles};
  `,
});

const SubmitButtonStyles = breakpoints({
  base: css`  
    width: 200px;
    margin-top: ${theme.UNIT(8)};
  `,
});


const LoaderStyles = breakpoints({
  base: css`
    background: none;
    padding-bottom: ${theme.UNIT(100)};
  `,
});

const ThankyouStyles = breakpoints({
  base: css`
    padding: ${theme.UNIT(2)};
    width: 100%;
    box-sizing: border-box;
    flex-grow: 1;
    color: ${theme.COLOR('text')};
    font-weight: 400;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    text-align: center;
    p {
      margin: ${theme.UNIT(2)};
    }
  `,
});

const ThankyouMessageStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    font-size: 1.25em;
  `,
});


const IndicatorStyles = breakpoints({
  base: css`
    width: 20px;
    margin: 0 16px;
  `,
});

const InputCheckboxStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    gap: ${theme.UNIT(5)};
    color: ${theme.COLOR('text')};
    margin-bottom: ${theme.UNIT(6)};
    align-self: stretch;

    .shp--input-checkbox__checkbox {
      margin-left: 0;
    }
    .shp--icon {
      ${theme.FILL(ORANGE)}
    }
  `,
});

const UpdatesStyles = breakpoints({
  base: css`
    ${InputCheckboxStyles};
    margin-bottom: ${theme.UNIT(8)};
  `,
});

const TypeFieldStyles = breakpoints({
  base: css`
    
  `,
});


const TypeStyles = breakpoints({
  base: css`
    
  `,
});




const ErrorsStyles = breakpoints({
  base: css`
    color: ${theme.COLOR('red')};
    padding: ${theme.UNIT(6)} ${theme.UNIT(6)} ${theme.UNIT(7)};
    margin-top: ${theme.UNIT(4)};
    margin-bottom: ${theme.UNIT(4)};
    font-weight: 500;
    border: 2px dotted ${theme.COLOR('red')};
    font-size: ${theme.FONT_SIZE(14)};

    ul {
      padding-left: ${theme.UNIT(4)};
    }

    li {
      margin: ${theme.UNIT(2)} 0; 
      padding-left: 0;
    }
  `,
});

// -------- Components -------->

const TYPE = 'type';
const PARTICIPATE_MESSAGE = 'participateDetails';
const TRANSFERABLE_MESSAGE = 'transferableDetails';
const GIVEN_NAME = 'givenName';
const FAMILY_NAME = 'familyName';
const EMAIL = 'email';
const COMPANY = 'company';
const STAKEHOLDER_TYPE = 'stakeholderType';
const URL = 'url';
const FORM_NAME = 'contact';
const AGREE = 'agreeToTerms';
const BECOME_MEMBER = 'becomeAMember';
const SHOW_NAME = 'AgreeToShowName';
const BE_CONTACTED = 'AgreeToBeContacted';
const RECEIVE_UPDATES = 'receiveUpdates';
const ASSOCIATED_COUNTRY = 'associatedCountry';
const HOW_DID_YOU_HEAR_ABOUT_US = 'howDidYouHearAboutUs';
const OTHER_ORGANISATIONS = 'otherOrganisations';


const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Loader: styled(FormLoader)`${LoaderStyles}`,
    Thankyou: styled.div`${ThankyouStyles}`,
      ThankyouMessage: styled.div`${ThankyouMessageStyles}`,

    Agree: styled(InputCheckbox).attrs({ id: AGREE, name: AGREE })`${InputCheckboxStyles}`,
    BecomeAMember: styled(InputCheckbox).attrs({ id: BECOME_MEMBER })`${InputCheckboxStyles}`,
    AgreeToContact: styled(InputCheckbox).attrs({ id: BE_CONTACTED })`${InputCheckboxStyles}`,
    ShowName: styled(InputCheckbox).attrs({ id: SHOW_NAME })`${InputCheckboxStyles}`,
    Updates: styled(InputCheckbox).attrs({ id: RECEIVE_UPDATES })`${UpdatesStyles}`,
    
    Indicator: styled(DottedChevronDownIcon)`${IndicatorStyles}`,

    Form: styled.form`${FormStyles}`,

      TypeField: styled(Field).attrs({ id: TYPE })`${TypeFieldStyles}`,
        Type: styled(Select).attrs({ id: TYPE, placeholder: 'Select' })`${TypeStyles}`,
      GivenNameField: styled(Field).attrs({ id: GIVEN_NAME })`${FieldStyles}`,
        GivenName: styled(Input).attrs({ id: GIVEN_NAME, placeholder: '' })`${InputStyles}`,
      FamilyNameField: styled(Field).attrs({ id: FAMILY_NAME })`${FieldStyles}`,
        FamilyName: styled(Input).attrs({ id: FAMILY_NAME, placeholder: '' })`${InputStyles}`,
      EmailField: styled(Field).attrs({ id: EMAIL })`${FieldStyles}`,
        Email: styled(Input).attrs({ id: EMAIL, placeholder: '' })`${InputStyles}`,
      AssociatedCountryField: styled(Field).attrs({ id: ASSOCIATED_COUNTRY })`${FieldStyles}`,
        AssociatedCountry: styled(Select).attrs({ id: ASSOCIATED_COUNTRY, placeholder: 'Country' })`${SelectStyles}`,
      CompanyField: styled(Field).attrs({ id: COMPANY })`${FieldStyles}`,
        Company: styled(Input).attrs({ id: COMPANY, placeholder: '' })`${InputStyles}`,
      OrganisationTypeField: styled(Field).attrs({ id: STAKEHOLDER_TYPE })`${FieldStyles}`,
        OrganisationType: styled(Select).attrs({ id: STAKEHOLDER_TYPE, placeholder: 'Stakeholder Type' })`${SelectStyles}`,
      ParticipateMessageField: styled(Field).attrs({ id: PARTICIPATE_MESSAGE })`${FieldStyles}`,
        ParticipateMessage: styled(TextArea).attrs({ id: PARTICIPATE_MESSAGE })`${TextAreaStyles}`,
      TransferrableMessageField: styled(Field).attrs({ id: TRANSFERABLE_MESSAGE })`${FieldStyles}`,
        TransferrableMessage: styled(TextArea).attrs({ id: TRANSFERABLE_MESSAGE })`${TextAreaStyles}`,

      HowDidYouHearAboutUsField: styled(Field).attrs({ id: HOW_DID_YOU_HEAR_ABOUT_US })`${FieldStyles}`,
        HowDidYouHearAboutUs: styled(Select).attrs({ id: HOW_DID_YOU_HEAR_ABOUT_US, placeholder: 'Please Select' })`${SelectStyles}`,

      OtherOrganisationsField: styled(Field).attrs({ id: OTHER_ORGANISATIONS })`${FieldStyles}`,
        OtherOrganisations: styled(Select).attrs({ id: OTHER_ORGANISATIONS, placeholder: 'Select An Organisation' })`${SelectStyles}`,



      Errors: styled(ErrorList)`${ErrorsStyles}`,

    SubmitButton: styled(Button)`${SubmitButtonStyles}`,
};
  
export const ContactForm: Shapeable.FC<ContactFormProps> = (props) => {
  const { className, types, typesLabel, typesRequired, typesRequiredError, associatedCountries } = props;
  const form = useForm(FORM_NAME);

  const stakeholderTypeOptions: SelectOption[] = [
    {
      value: 'Academia',
      label: 'Academia',
    },
    {
      value: 'Community',
      label: 'Community',
    },
    {
      value: 'Healthcare',
      label: 'Healthcare',
    },
    {
      value: 'Law',
      label: 'Law',
    },
    {
      value: 'Media',
      label: 'Media',
    },
    {
      value: 'Mental Health and Human Rights',
      label: 'Mental Health and Human Rights',
    },
    {
      value: 'Policymakers',
      label: 'Policymakers',
    },
  ];


  const howDidYouHearAboutUsOptions: SelectOption[] = [
    {
      value: 'IASP',
      label: 'IASP',
    },
    {
      value: 'United',
      label: 'United',
    },
    {
      value: 'Global Mental Health Network',
      label: 'Global Mental Health Network',
    },
    {
      value: 'Throughline',
      label: 'Throughline',
    },
    {
      value: 'Social Media',
      label: 'Social Media',
    },
    {
      value: 'Other',
      label: 'Other',
    },
  ];

  const otherOrganisationsOptions: SelectOption[] = [
    {
      value: 'IASP',
      label: 'IASP',
    },
    {
      value: 'Global Mental Health Network',
      label: 'Global Mental Health Network',
    },
    {
      value: 'Throughline',
      label: 'Throughline',
    },
    {
      value: 'Other',
      label: 'Other (Please Specify)',
    },
  ];

  const associatedCountryOptions: SelectOption[] = associatedCountries.length && associatedCountries.map((country: Economy) => ({ label: country.name, value: country.name }));
  const typeOptions: SelectOption[] = types.length && types.map((type: string) => ({ label: type, value: type })); 

  const { values, patchValues } = form;
  const [ token, setToken ] = useState('');
  const [ errors, setErrors ] = useState<string[]>([]);
  const [ errorFields, setErrorFields ] = useState<string[]>([]);

  const { Link } = useLink();

  const t = useLang();
  const lang = useActiveLang();

  const page = usePage();
  
  const formState: Dictionary<any> = { 
    ...values, 
  };

  const SITE_RECAPTCHA_KEY = useSiteRecaptchaKey();
  const TERMS_PAGE_PATH = '/terms-of-use';
  const SITE_OWNER_NAME = useSiteOwnerName();
  const PRIVACY_POLICY_PAGE_PATH = usePrivacyPagePath();

  const onChange = (name: string) => (value: string | SelectOption) => { patchValues({ [name]: value }); setErrors([]); setErrorFields(without(errorFields, name)); };

  const onSubmit = async(event: React.FormEvent) => {
    event.preventDefault();

    const e = [];
    const ef = [];
    
    if (!token) {
      errors.push(t("Please check the I'm not a robot checkbox to validate you are human"));
    }

    if (!!typeOptions.length && typesRequired && !formState[TYPE]) {
      e.push(t(typesRequiredError));
      ef.push(TYPE);
    }
    
    if (trim(formState[GIVEN_NAME]) === '') {
      e.push(t("Please enter your given name."));
      ef.push(GIVEN_NAME);
    }

    if (trim(formState[FAMILY_NAME]) === '') {
      e.push(t("Please enter your family name."));
      ef.push(FAMILY_NAME);
    }

    if (trim(formState[PARTICIPATE_MESSAGE]) === '') {
      e.push(t("Please provide the details of your request in the participate message field."));
      ef.push(PARTICIPATE_MESSAGE);
    }

    if (trim(formState[TRANSFERABLE_MESSAGE]) === '') {
      e.push(t("Please provide the details of your request in the activites and skills field."));
      ef.push(TRANSFERABLE_MESSAGE);
    }

    if (trim(formState[EMAIL]) !== '' && !EmailValidator.validate(formState[EMAIL])) {
      e.push(t("Please check that your email address is VALID"));
      ef.push(EMAIL);
    }

    if (trim(formState[URL]) !== '' && !validUrl.isWebUri(formState[URL])) {
      e.push(t("Please provide a VALID URL in the Company Website URL field"));
      ef.push(URL);
    }
    
    if (trim(formState[AGREE]) === '') {
      e.push(t("Please confirm you agree with our terms and conditions"));
      ef.push(AGREE);
    }

    setErrorFields(ef);
    setErrors(e);

    if (e.length) {
      return false;
    }

    const data = { ...formState, 'g-recaptcha-response': token };

    if (props.beforeSubmit) {
      props.beforeSubmit();
    };

    form.startSubmit();
    const response = await onSubmitNetlifyForm(FORM_NAME, page.path, data);
    setToken('');
    form.endSubmit();

    if (props.afterSubmit) {
      props.afterSubmit();
    };

    return false;

  };
  
  const onCaptchaChange = (token: any) => {
    setToken(token);
  };

  const isSubmitDisabled = !!((!token && SITE_RECAPTCHA_KEY) || form.isSubmitting);
  const hasErrors = !!errors.length;
  // const { isReady } = useReady();

  const agreeText = sprintf(t("I agree to the [Terms & Conditions](%s)"), TERMS_PAGE_PATH)

  return (
    <My.Container className={className}>
      {
        (form.isSubmitting) && 
        <My.Loader color="primary">{t("Please wait...")}</My.Loader>
      }
      {
        !form.isSubmitting && (
        (form.isSubmitted) ?
        <My.Thankyou>
          <My.ThankyouMessage>
          <p>
          {t("Thankyou, your submission has been received.")}
          </p>
          <p>
          {t('We will be in touch soon.')}
          </p>
          </My.ThankyouMessage>
        </My.Thankyou> :
        <My.Form name={FORM_NAME} onSubmit={onSubmit} method="post" data-netlify-recaptcha="true" data-netlify="true">
          <input type="hidden" name="form-name" value={FORM_NAME} />

          {
            typeOptions.length && 
            <My.TypeField label={t(typesLabel)}>
              <My.Type 
                hasError={includes(errorFields, TYPE)}
                onChange={onChange(TYPE)} 
                options={typeOptions}
                value={formState[TYPE] || ''}
                borderStyle="dotted"
              />
            </My.TypeField>

          }
          <My.GivenNameField label={t('Given Name or Pseudonym:')}>
            <My.GivenName hasError={includes(errorFields, GIVEN_NAME)} onChange={onChange(GIVEN_NAME)} value={formState[GIVEN_NAME] || ''} />
          </My.GivenNameField>

          <My.FamilyNameField label={t('Family Name:')}>
            <My.FamilyName hasError={includes(errorFields, FAMILY_NAME)} onChange={onChange(FAMILY_NAME)} value={formState[FAMILY_NAME] || ''} />
          </My.FamilyNameField>

          <My.ShowName label={sprintf(t('I Wish To Protect My Real Name'), SITE_OWNER_NAME)} onChange={onChange(SHOW_NAME)} checked={formState[SHOW_NAME] === 'yes'} />


          <My.EmailField label={t('Email:')}>
            <My.Email hasError={includes(errorFields, EMAIL)} onChange={onChange(EMAIL)} value={formState[EMAIL] || ''} />
          </My.EmailField>

          <My.AgreeToContact label={sprintf(t('I Agree To Be Contacted At This Email Address'), SITE_OWNER_NAME)} onChange={onChange(BE_CONTACTED)} checked={formState[BE_CONTACTED] === 'yes'} />

          {
            associatedCountryOptions && 
            <My.AssociatedCountryField label={t('What Country Are You Associated With?:')}>
              <My.AssociatedCountry 
                onChange={onChange(ASSOCIATED_COUNTRY)} 
                options={associatedCountryOptions}
                value={formState[ASSOCIATED_COUNTRY] || ''}
                borderStyle="dotted"
                />
            </My.AssociatedCountryField>
          }

          <My.HowDidYouHearAboutUsField label={t('How Did You Hear About Us?:')}>
            <My.HowDidYouHearAboutUs
              onChange={onChange(HOW_DID_YOU_HEAR_ABOUT_US)} 
              options={howDidYouHearAboutUsOptions}
              value={formState[HOW_DID_YOU_HEAR_ABOUT_US] || ''}
              borderStyle="dotted"
            />
          </My.HowDidYouHearAboutUsField>


          <My.CompanyField label={t('Are You Associated With Any Organisations?:')}>
            <My.Company hasError={includes(errorFields, COMPANY)} onChange={onChange(COMPANY)} value={formState[COMPANY] || ''} />
          </My.CompanyField>
          

          <My.OtherOrganisationsField label={t('Are You Associated With Any Other Organisations?:')}>
            <My.OtherOrganisations
              onChange={onChange(OTHER_ORGANISATIONS)} 
              options={otherOrganisationsOptions}
              value={formState[OTHER_ORGANISATIONS] || ''}
              borderStyle="dotted"
            />
          </My.OtherOrganisationsField>

          {
            stakeholderTypeOptions.length && 
            <My.OrganisationTypeField label={t('What Type Of Stakeholder Are You?')}>
              <My.OrganisationType 
                onChange={onChange(STAKEHOLDER_TYPE)} 
                options={stakeholderTypeOptions}
                value={formState[STAKEHOLDER_TYPE] || ''}
                borderStyle="dotted"
              />
            </My.OrganisationTypeField>
          }

          <My.ParticipateMessageField label={t('What Motivates You To Participate?:')}>
            <My.ParticipateMessage hasError={includes(errorFields, PARTICIPATE_MESSAGE)} onChange={onChange(PARTICIPATE_MESSAGE)}>{formState[PARTICIPATE_MESSAGE] || ''}</My.ParticipateMessage>
          </My.ParticipateMessageField>

          <My.TransferrableMessageField label={t('What Relevant Prior Activites And Skills Do You Wish To Bring?:')}>
            <My.TransferrableMessage hasError={includes(errorFields, TRANSFERABLE_MESSAGE)} onChange={onChange(TRANSFERABLE_MESSAGE)}>{formState[TRANSFERABLE_MESSAGE] || ''}</My.TransferrableMessage>
          </My.TransferrableMessageField>

          <My.Agree onChange={onChange(AGREE)} valid={!includes(errorFields, AGREE)} checked={formState[AGREE] === 'yes'}>
          <MarkdownContent 
            text={agreeText} 
            embedComponents={{
              a: {
                component: MarkdownContentLinkTargetBlank
              }
            }}
          />
          </My.Agree>
          {
            SITE_RECAPTCHA_KEY && 
            <ReCAPTCHA
              sitekey={SITE_RECAPTCHA_KEY}
              onChange={onCaptchaChange}
            />
          }
          {
            hasErrors && 
            <My.Errors items={errors}>
            {t('Sorry, we need a bit more information:')}
            </My.Errors>
          }
          <My.SubmitButton type="submit" disabled={isSubmitDisabled}>{t('Submit')}</My.SubmitButton>
        </My.Form>
        )
      } 
      
    </My.Container>
  )
};

ContactForm.defaultProps = ContactFormDefaultProps;
ContactForm.cls = cls;